import { useCabinetStore } from '~/stores/cabinet';
const isBlockedConfirmationEmail = ref(false);
/**
 * Composable for sending a confirmation email.
 * It uses the `cabinetStore` to send the email
 * and manages the state to prevent multiple emails being sent within a short period.
 *
 * @async
 * @function useConfirmationEmail
 * @returns {Promise<void>} This function does not return a value.
 */

export const useConfirmationEmail = async () => {
  const cabinetStore = useCabinetStore();
  const { sendConfirmationEmail } = cabinetStore;

  if (!isBlockedConfirmationEmail.value) {
    const response = await sendConfirmationEmail();

    if (!response) {
      isBlockedConfirmationEmail.value = !response;

      setTimeout(() => {
        isBlockedConfirmationEmail.value = response;
      }, 60000);
    }
  }
};